* {
  //
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.sobrus-med-container {
  padding-top: 60px;
  background-color: #f4f4f4;
  min-height: 100vh;
  padding-bottom: 110px;
  // min-width: 1238px;
}

.react-datepicker__close-icon:after {
  background-color: #00b1d8 !important ;
}
.comment-textarea {
  min-height: 140px;
  resize: none;
}
.med-primary-btn-red {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: rgba(0, 177, 216, 0.08);
  color: #02829f;
}

.med-primary-btn {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: rgba(0, 177, 216, 0.08) !important;
  color: #02829f !important;
}

.med-primary-btn:hover {
  background-color: rgba(0, 177, 216, 0.08) !important;
  color: #02829f !important;
}

.med-primary-btn-important {
  background-color: rgba(0, 177, 216, 0.08) !important;
  color: #02829f !important;
}
.med-primary-btn-important:hover {
  background-color: rgba(0, 177, 216, 0.08) !important;
  color: #02829f !important;
}

.bread-crumb-container {
  margin: 0.3rem 0 !important;
}
.med-container {
  max-width: 98.5% !important;
  scroll-behavior: smooth;
}
.details-tabs-container {
  max-width: 100% !important;
  margin-left: initial !important;
  margin-right: initial !important;
}
.med-table-refresh-btn {
  margin-right: 10px;
}
.icon-tag {
  margin: 0 !important;
  height: 48px !important;
  width: 48px !important;
  border-radius: 13px !important;
  margin-right: 10px !important;
  padding: 0 !important;
  position: relative !important;
  img {
    width: 50%;
  }
}
.title-tag {
  font-weight: 300 !important;
  font-size: 0.875rem !important;
  color: rgba(0, 0, 0, 0.85) !important;
  margin-bottom: 18px !important;
}
.custom-tablePage {
  background-color: rgba(0, 174, 212, 0.04) !important;
  color: #02829f !important;
}
.TablePage__primary {
  background-color: rgba(0, 174, 212, 0.04) !important;
  color: #02829f !important;
}
.sob-form-control {
  height: 55px !important;
  min-width: initial !important;
  padding: 0.5rem 1rem;
}

.clickable-row {
  cursor: pointer;
}

.sob-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.cart_title_header_container_button {
  background-color: #b9b9b915 !important;
  color: #9d9d9d !important;
}
.cart_title_header_container {
  align-items: center;
}
.cart_title {
  margin-left: 1rem;
  margin-bottom: 0px;
}
.sob-error-msg {
  color: #f05a29;
  //
  font-size: 0.775rem;
  margin-top: 0.25rem;
  width: 100%;
}

.modal-btn {
  height: 100%;
  min-width: 180px;
  padding: 1rem 2rem !important;
}
.modal-save-btn {
  background-color: rgba(0, 177, 216, 0.09) !important;
  color: #02829f !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.status-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
  min-width: 111px;
  min-height: 38px;
}
.large-td-text-container {
  max-width: 200px;
}

.monSite-sideMenuItem {
  display: flex;
  align-items: center;
}
.hight-lighter {
  font-weight: 600 !important;
  color: #00acd2 !important;
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
}
.sob-btn-custom {
  width: 100% !important;
  padding-right: 70px !important;
}
.CustomButton__sob-text {
  max-width: initial !important;
}
.shortcut-btn {
  // white-space: nowrap !important;
}

.patient-external-link_icon_container {
  margin-left: 10px;
  height: 35px;
  width: 35px;
  border-radius: 8px;
  margin-right: 10px;
  position: relative;
  background-color: rgba(0, 174, 212, 0.08);
  color: var(--sob-primary);
  position: relative;
  cursor: pointer;
}
.patient-external-link_icon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.sob-btn-group::-webkit-scrollbar {
  display: none;
}

.sob-btn-group {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tabPanel {
  overflow: hidden;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  border-bottom-left-radius: 11px;
}
.sob-tabs-container {
  background-color: transparent !important;
}
.btn-container {
  display: flex;
  align-items: center;
  height: 100%;
  align-self: flex-end;
  // margin-bottom: 15px;
  flex-wrap: nowrap;
}
.AllergieAddUpdateModal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  .card {
    width: 58%;
    // min-width: 800px;
    //  min-height: 50%;
    // min-height: 476px;
    height: fit-content;
    position: relative;
  }
  .modal-footer-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
.rdw-option-wrapper {
  background-color: rgba(185, 185, 185, 0.08);
}
.rdw-option-active {
  outline: none;
  box-shadow: none;
  background-color: rgba(185, 185, 185, 0.35) !important;
}
.text-editor-bold {
  width: 38.8px;
  height: 37px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0;
}
.text-editor-bold:hover {
  outline: none;
  box-shadow: none;
}
.text-editor-bold img {
  width: 11.26px !important;
  height: 13px !important;
}
.text-editor-underline {
  width: 38.8px;
  height: 37px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0;
}
.text-editor-underline:hover {
  outline: none;
  box-shadow: none;
}
.text-editor-underline img {
  width: 7.5px !important;
  height: 13px !important;
}
.text-editor-color {
  width: 38.8px;
  height: 37px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0;
}
.text-editor-color:hover {
  outline: none;
  box-shadow: none;
}
.text-editor-color img {
  width: 18px !important;
  height: 18px !important;
}
.text-editor-italic {
  display: none !important;
}
.text-editor-block-type {
  display: none !important;
}
.text-editor-font-size {
  display: none !important;
}
.text-editor-font-family {
  display: none !important;
}
.text-editor-list {
  display: none !important;
}
.text-editor-text-align {
  display: none !important;
}
.text-editor-link {
  display: none !important;
}
.text-editor-emoji {
  display: none !important;
}
.text-editor-embedded {
  display: none !important;
}
.text-editor-image {
  display: none !important;
}
.text-editor-remove {
  display: none !important;
}
.text-editor-history {
  display: none !important;
}
.text-editor-strikethrough {
  display: none !important;
}
.text-editor-strikethrough {
  display: none !important;
}
.text-editor-monospace {
  display: none !important;
}
.text-editor-superscript {
  display: none !important;
}
.text-editor-subscript {
  display: none !important;
}
.toolbarClassName {
  height: 60px;
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.08);
  border-width: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.wrapperClassName {
  border-radius: 8px;
  border: solid 1px #c6c6c6;
  min-height: 157px;
}
.public-DraftStyleDefault-block {
  padding-left: 15px;
  // min-height: 77px;
  margin-bottom: 20px !important;
  // background-color: red;
}
.rdw-colorpicker-modal {
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.08);
  border-width: 0;
  top: 44px;
}
.rdw-colorpicker-modal-style-label {
  //
}
.rdw-colorpicker-option {
  border-radius: 6px;
  overflow: hidden;
}
.rdw-colorpicker-option:hover {
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.08);
}
.rdw-mention-link {
  color: #02829f !important;
}
.rdw-mention-link {
  padding: 4px 11px 3px 12px;
  border-radius: 10px;
  background-color: rgba(0, 177, 216, 0.08);
}
.rdw-suggestion-dropdown {
  width: 496px !important;
  border-radius: 8px !important;
  border: solid 0.5px #c6c6c6 !important;
  background-color: #fff !important;
  padding: 15px !important;
  max-height: 240px !important;
}
.rdw-suggestion-option {
  //
  font-size: 14px !important;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.rdw-editor-main {
  overflow: visible;
}
.tab-icon-container {
  width: 45px;
  height: 45px;
  border-radius: 9px;
  background-color: rgba(185, 185, 185, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
}
.tab-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: all 0.2s;
}
.react-datepicker__aria-live {
  display: none !important;
}

.sob-accordion {
  padding: 0px !important;
}

.sob-breadcrumb-content {
  h3 {
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  h6 {
    font-size: 16px !important;
    font-weight: 400 !important;
    margin: 0;
  }
}

.sob-input-select--is-disabled {
  .sob-input-select__control {
    background: #f9f9f9 !important;
  }
}
