.LoadingPage__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.LoadingPage__container {
  .MuiLinearProgress-colorPrimary {
    background-color: rgba(0, 177, 216, 0.07);
  }
  .MuiLinearProgress-root {
    height: 11px;
    border-radius: 4px;
    overflow: hidden;
  }
  .LoadingPage__img {
    margin-bottom: 40px;
  }
  .loader-container {
    max-width: 250px;
    width: 100%;
  }
  .custom-linear-progress-valid {
    .MuiLinearProgress-bar {
      background-color: #00b1d8 !important;
      border-radius: 4px !important;
    }
  }
  .LoadingPage__desc {
    font-size: 14px;

    margin-top: 15px;
  }
}
