.NavBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1100;
}

.NavBar {
  .NavSubMenu__link {
    text-decoration: none;
    display: inline-block;
    height: 100%;
    width: 100%;
    padding-bottom: 8px;
    padding-top: 17px;
    padding-left: 7.5px;
    padding-right: 7.5px;
    position: relative;
    height: 55px;
  }
  .NavSubMenu__item {
    padding-bottom: 0;
    padding-top: 0;
  }
  .NavSubMenu__item-container {
    padding-left: 0;
    padding-right: 0;
  }
  .hasSubMenu:after {
    display: none;
  }
  .Logo {
    font-size: 18px;
    font-weight: 300;
    color: #18b1d4;
    text-transform: uppercase;
    line-height: 50px;
    outline: 0;
    text-decoration: none;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    padding-top: 6px;
  }
  .Logo span {
    font-size: 18px;
    color: #18b1d4;
    text-transform: uppercase;
    font-weight: 700;
  }
}

.NavUserProfile-profile-dropdown-content {
  z-index: 10000 !important;
  top: 68px !important;
}
