.CancellationBanner {
  width: 100%;
  height: 48px;
  background: #df4751;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  p {
    margin: 0px;
  }
}
